<template>
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g>
			<path
				d="M7 7H17M17 7V17M17 7L7 17"
				stroke="inherit"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round" />
		</g>
	</svg>
</template>
